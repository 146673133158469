import React from 'react';

interface Segment {
    text: string;
    speaker: string;
}

interface ProcessedSegment {
    text: string;
    speaker: string;
}

function extractTextAndSpeaker(segments: Segment[]): ProcessedSegment[] {
    return segments.map(({ text, speaker }) => ({ text, speaker }));
}

interface ConversationDisplayProps {
    segments: Segment[];
}

const ConversationDisplay: React.FC<ConversationDisplayProps> = ({ segments }) => {
    const processedSegments = extractTextAndSpeaker(segments);

    return (
        <div className="max-w-2xl mx-auto p-4">
            <h4 className="text-2xl font-bold mb-4"> Transcript</h4>
            {processedSegments?.map((segment, index) => (
                <div key={index} className="pl-4 bg-gray-100 rounded-lg">
                    <p className="font-semibold text-blue-600"><b>{segment.speaker}</b></p>
                    <p className="mt-2">{segment.text}</p>
                </div>
            ))}
        </div>
    );
};

export default ConversationDisplay;