import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Table } from 'react-bootstrap';
import { get } from '../../services/apiServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBank, faAlignJustify, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CreateFileStatus } from '../../interfaces';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'ocrapola';

interface TranscriptionListProps {
    processid: string;
    selectionDone: any;
    user: any;
}

const DocumentTypeSelector: React.FC<TranscriptionListProps> = ({ processid, user, selectionDone }) => {
    const [loadingLegal, setLoadingLegal] = React.useState(false);
    const [loadingText, setLoadingText] = React.useState(false);

    const fetchData = async (templateid: number) => {
        try {
            const response = await get('/createtranscript/' + processid + '/' + templateid, user ? user?.token : null);
            const pollResponse = response.data as CreateFileStatus;
            downloadFile(pollResponse.filename)
        } catch (err) {
            console.log('Error fetching data');
        }
    };

    function downloadFile(filename: string) {
        const link = document.createElement('a');
        link.href = `${API_BASE_URL}/file/${filename}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoadingLegal(false)
        setLoadingText(false)
    }

    function typeSelected(type: number) {
        switch (type) {
            case 1:
                setLoadingLegal(true)
                break;
            case 2:
                setLoadingText(true)
                break;
        }
        fetchData(type);
    }

    return (
        <>
            <h3>Document Type</h3>
            <p>Select from these templates the type of document you would like.</p>
            <Row >
                <Col>
                    <Button variant="outline-primary" type="button" size="sm" onClick={() => typeSelected(2)} className='m-2'>
                        {loadingText &&
                            <>
                                <FontAwesomeIcon icon={faSpinner} className="rotate" size='2x' />
                                <br /> <span>Legal Document</span>
                            </>
                        }
                        {!loadingText &&
                            <>
                                <FontAwesomeIcon icon={faAlignJustify} size='2x' /><br /> <span>Text Document</span>
                            </>
                        }
                    </Button>
                    <Button variant="outline-primary" type="button" size="sm" onClick={() => typeSelected(1)} className='m-2' disabled={loadingLegal}>
                        {loadingLegal &&
                            <>
                                <FontAwesomeIcon icon={faSpinner} className="rotate" size='2x' />
                                <br /> <span>Legal Document</span>
                            </>
                        }
                        {!loadingLegal &&
                            <>
                                <FontAwesomeIcon icon={faBank} size='2x' /><br /> <span>Legal Document</span>
                            </>
                        }
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default DocumentTypeSelector;
