import { Container, Row, Col } from 'react-bootstrap';
import PlainHeader from '../../components/plainheader';
import PublicFooter from '../../components/footer';

const TermsOfService = () => {

    return (
        <>
            <PlainHeader />
            <Container className='main-container mt-5'>
                <Row className="justify-content-md-center pt-5">
                    <Col md={10}>
                        <h3>Terms and Conditions of Use</h3>
                        <h5>1. Introduction</h5>
                        <p>
                            Welcome to TRANSCRIBO S.A.S., a South African company with registered office at 229 Argyle Street, Mossel Bay Golf Estate, Mossel Bay and registered with
                            SARS under Codebin Consulting Services Pty (Ltd). Please carefully read the following pages. It will take you approximately 20 minutes.
                            These Terms of Service (“Terms”, “Terms of Service”) govern your use of our web pages located at [site] Privacy Policy also governs
                            your use of our Service and explains how we collect, safeguard and disclose information that results from your use of our web pages.
                            Please read it <a href="/privacy" >here</a>.
                        </p>
                        <p> Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You acknowledge that you have read and
                            understood Agreements, and agree to be bound to them. If you do not agree with (or cannot comply with) Agreements, then you may not use the Service,
                            but please let us know by emailing at ralphklein72@gmail.com so we can try to find a solution. These Terms apply to all visitors, users and others
                            who wish to access or use Service. Thank you for being responsible.
                        </p>
                        <h5>2. Communications</h5>
                        <p>
                            By creating an Account on our Service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may
                            opt out of receiving any, or all, of these communications from us by following the unsubscribe link.
                        </p>
                        <h5>3. Purchases</h5>
                        <p>
                            If you wish to purchase any product or service made available through Service (“Purchase”), you may be asked to supply certain information relevant to your
                            Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information.
                            You represent and warrant that:
                            <ul>
                                <li>
                                    you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that
                                </li>
                                <li>
                                    the information you supply to Codebin Consultin Services is true, correct and complete.  We may employ the use of third party services for the purpose of facilitating payment
                                    and the completion of Purchases. By submitting your information, you grant Codebin Consultin Services the right to provide the information to these third
                                    parties subject to our Privacy Policy.  We reserve the right to refuse or cancel your order at any time for reasons including but not
                                    limited to: product or service availability, errors in the description or price of the product or service, error in your order,
                                    default of payment, or other reasons.  We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.
                                </li>
                            </ul>
                        </p>
                        <h5>4. Subscriptions</h5>
                        <p>
                            Some parts of Service are billed on a subscription basis (“Subscription(s)”). You will be billed in advance on a recurring and periodic basis (“Billing Cycle”).
                            Billing cycles are set either on a monthly or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.  At the end of each
                            Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or Transcribo cancels it. You may cancel your Subscription
                            renewal either through your online account management page or by contacting our customer support team.  A valid payment method, including credit card, is required to
                            process the payment for your subscription. You shall provide Codebin Consultin Services with accurate and complete billing information including full name, address,
                            state, zip code, telephone number, and valid payment method information. By submitting such payment information, you automatically authorize Codebin Consultin Services
                            to charge all Subscription fees incurred through your account to any such payment instruments.  Should automatic billing fail to occur for any reason, Codebin Consultin Services
                            will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period
                            as indicated on the invoice.  If you are eligible for a discount, please note that you cannot use more than 1 (one) discount per subscription.
                        </p>
                        <h5>5. Free trial</h5>
                        <p>
                            Codebin Consultin Services may, at its sole discretion, offer a Subscription with a free trial for a limited period of time (“Free Trial”). You may be required to
                            enter your billing information in order to sign up for the Free Trial. If you do enter your billing information when signing up for the Free Trial, you will not be
                            charged by Codebin Consultin Services until the Free Trial has expired. On the last day of the Free Trial period, unless you cancelled your Subscription, you will be
                            automatically charged the applicable Subscription fees for the type of Subscription you have selected. At any time and without notice, Codebin Consultin Services
                            reserves the right to:
                            <ul>
                                <li>
                                    modify the Terms of Service of the Free Trial offer, or
                                </li>
                                <li>
                                    cancel such Free Trial offer.
                                </li>
                            </ul>
                        </p>
                        <h5>6. Fee changes</h5>
                        <p>
                            Codebin Consultin Services, in its sole discretion and at any time, modify Subscription fees for the Subscriptions. Any Subscription fee change will
                            become effective at the end of the then-current Billing Cycle. Codebin Consultin Services will provide you with a reasonable prior notice of any
                            change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective. Your continued use of
                            the Service after the Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.
                        </p>
                        <h5>7. Refunds</h5>
                        <p>
                            All monthly and annual purchases are non-refundable. However, if you believe you have a valid reason for a refund, you can contact us at ralphklein72@gmail.com
                            within 14 days of your purchase to discuss your situation. You can cancel your subscription at any time through your profile page by clicking "Cancel Subscription".
                            Your cancellation will take effect at the end of the current billing period, and you will not be charged for subsequent periods. Please note that refunds are only
                            available in cases where there is a clear error or fault in the service provided. We reserve the right to review and approve all refund requests on a case-by-case
                            basis. If you are eligible for a refund, it will be processed within 14 days of approval, and the amount will be credited to your original method of payment. For any
                            issues or questions regarding refunds or cancellations, please contact our support team.
                        </p>
                        <h5>8. Content</h5>
                        <p>
                            Our Service allows you to post, link, store, share, and otherwise make available certain information, text, graphics, videos, or other material (“Content”).
                            You are responsible for the Content that you post on or through the Service, including its legality, reliability, and appropriateness. By posting Content on or
                            through the Service, you represent and warrant that:
                            <ul>
                                <li>
                                    the Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms, and
                                </li>
                                <li>
                                    the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights, or any other rights of
                                    any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright. You retain any and all of your rights to any
                                    Content you submit, post, or display on or through the Service, and you are responsible for protecting those rights. We take no responsibility and assume no
                                    liability for the Content you or any third party posts on or through the Service. Codebin Consultin Services has the right but not the obligation to monitor and
                                    edit all Content provided by users.
                                </li>
                            </ul>
                            Users of services provided by Codebin Consultin Services may also use our artificial intelligence technology or copywriting
                            services to obtain new Content. If this is your case, you are responsible for using this Content and will be responsible for ensuring that this content does not
                            violate the privacy rights, publicity rights, copyrights, contract rights, or any other rights of any person or entity. In certain use cases, our artificial
                            intelligence may create content that is deemed inappropriate or offensive. Codebin Consultin Services will not be held legally responsible if that happens.
                            Codebin Consultin Services strictly prohibits the generation of any content that is pornographic, sexually explicit, violent, hateful, or otherwise not safe for
                            work (NSFW). Users are not permitted to use our services to create adult-only or NSFW material under any circumstances. Accounts found in violation of this policy
                            may be immediately terminated without warning.

                            When you upload or post content to our Applications, you confirm that you:

                            <ul>
                                <li>
                                    have the legal right to do so;
                                </li>
                                <li>
                                    are not prevented to do so (for example, by any applicable law or confidentiality obligations);
                                </li>
                                <li>
                                    are not acting in violation of the intellectual property rights (including moral rights) of any person (which includes natural persons and body of persons - corporate or unincorporate), or their right to privacy, when doing so;
                                </li>
                                <li>
                                    have the explicit consent to do so from the people featured in your videos (or similar) and agree to supply their explicit written consent which you obtained on our request
                                </li>
                                <li>
                                    have the explicit consent to do so from the people featured in your videos (or similar) and agree to supply their explicit written consent which you obtained on our request
                                </li>
                            </ul>
                            You warrant that any such contribution by you (by uploading your content to our Applications) complies with these terms, and you will be liable to us and indemnify us
                            for any breach of this warranty. This means you will be responsible for any loss or damage we suffer as a result of your breach of warranty.We also have the right to
                            disclose your identity to any third party who is claiming that any content posted or uploaded by you to our Applications constitutes a violation of their intellectual
                            property rights, or of their right to privacy.
                        </p>
                        <h5>9. Prohibited Uses</h5>
                        <p>
                            You may use the Service only for lawful purposes and in accordance with the Terms. You agree not to use the Service:
                            <ul>
                                <li>
                                    In any way that violates any applicable national or international law or regulation.
                                </li>
                                <li>
                                    For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.
                                </li>
                                <li>
                                    To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail," "chain letter," "spam," or any other similar solicitation.
                                </li>
                                <li>
                                    To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity.
                                </li>
                                <li>
                                    In any way that infringes upon the rights of others or is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.
                                </li>
                                <li>
                                    To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Service or which, as determined by us, may harm or offend the Company or users of the Service or expose them to liability.
                                </li>
                            </ul>
                        </p>
                        <p>
                            Additionally, you agree not to:
                            <ul>
                                <li>
                                    Use the Service in any manner that could disable, overburden, damage, or impair the Service or interfere with any other party's use of the Service, including their ability to engage in real-time activities through the Service.
                                </li>
                                <li>
                                    Use any robot, spider, or other automatic device, process, or means to access the Service for any purpose, including monitoring or copying any of the material on the Service.
                                </li>
                                <li>
                                    Use any manual process to monitor or copy any of the material on the Service or for any other unauthorized purpose without our prior written consent.
                                </li>
                                <li>
                                    Use any device, software, or routine that interferes with the proper working of the Service.
                                </li>
                                <li>
                                    Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.
                                </li>
                                <li>
                                    Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Service, the server on which the Service is stored, or any server, computer, or database connected to the Service.
                                </li>
                                <li>
                                    Attack the Service via a denial-of-service attack or a distributed denial-of-service attack.
                                </li>
                                <li>
                                    Take any action that may damage or falsify the Company's rating.
                                </li>
                                <li>
                                    Otherwise attempt to interfere with the proper working of the Service.
                                </li>
                            </ul>
                        </p>
                        <h5>10. Analytics</h5>
                        <p>
                            We may use third-party Service Providers to monitor and analyze the use of our Service.
                            <br />
                            <b>Google Analytics</b>
                            <br />
                            Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of
                            our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.
                            For more information on the privacy practices of Google, please visit the Google Privacy Terms web page. We also encourage you to review Google's policy for
                            safeguarding your data: <a href="https://support.google.com/analytics/answer/6004245">https://support.google.com/analytics/answer/6004245</a>.
                        </p>
                        <h6>11. No Use By Minors</h6>
                        <p>
                            The Service is intended only for access and use by individuals at least eighteen (18) years old. By accessing or using any of the Company's services, you warrant and represent that you are at least eighteen (18) years of age and have the full authority, right, and capacity to enter into this agreement and abide by all of the terms and conditions of the Terms. If you are not at least eighteen (18) years old, you are prohibited from both accessing and using the Service.
                        </p>
                        <h5>12. Accounts</h5>
                        <p>
                            When you create an account with us, you guarantee that you are above the age of 18 and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on the Service.You are responsible for maintaining the confidentiality of your account and password, including but not limited to restricting access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.
                        </p>
                        <p>
                            You cannot share your account with other users.
                        </p>
                        <p>
                            You may not use as a username the name of another person or entity that is not lawfully available for use or a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar, or obscene.We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.
                        </p>
                        <h5>13. Intellectual Property</h5>
                        <p>
                            The Service and its original content (excluding Content provided by users or by our Content generation features), features, and functionality are and will remain the exclusive property of Codebin Consulting Services and its licensors.
                            The Service is protected by copyright, trademark, and other laws of foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Codebin Consulting Services.
                        </p>
                    </Col>
                </Row>
            </Container>
            <PublicFooter />
        </>
    );
};

export default TermsOfService;