import React, { useEffect } from 'react';

// Extend the Window interface
declare global {
  interface Window {
    chtlConfig?: {
      chatbotId: string;
    };
  }
}

const Chatbot: React.FC = () => {
  useEffect(() => {
    // Set the global configuration
    window.chtlConfig = { chatbotId: "7332371976" };

    // Create and load the script
    const script = document.createElement('script');
    script.async = true;
    script.setAttribute('data-id', '7332371976');
    script.id = 'chatling-embed-script';
    script.src = 'https://chatling.ai/js/embed.js';
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); 

  return null; // This component doesn't render anything
};

export default Chatbot;