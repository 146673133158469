import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { get } from '../../services/apiServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faShareSquare, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import './style.css';
import { TranscriptResponse } from '../../interfaces';
import { useAuth } from '../../context/AuthProvider';
import Clientheader from '../../components/clientheader';
import TranscriptDisplay from '../transcriptdisplay';
import { useNavigate } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'ocrapola';

interface TranscriptProps {
    selectionDone: any;
    processid: any;
}

const ShowTranscript: React.FC<TranscriptProps> = ({ processid, selectionDone }) => {
    const [data, setData] = useState<any>('');
    const [page, setPage] = useState<number>(0);
    const [copied, setCopied] = useState(false);
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await get('/fetchtranscript/' + processid, user ? user?.token : null);
                const pollResponse = response.data as TranscriptResponse;
                setData(pollResponse.data)
                setPage(1)
            } catch (err) {
                console.log('Error fetching data');
            }
        };
        fetchData()
    }, []);

    return (
        <>
            <Clientheader />
            <Container fluid className='text-start' >
                <Row >
                    {page === 0 &&
                        <Col md={12}>
                            <FontAwesomeIcon icon={faSpinner} className="rotate" size='2x' style={{ marginRight: '12px' }} />
                            <div>
                                <p>Loading .....</p>
                            </div>
                        </Col>
                    }

                    {page === 1 &&
                        <Col md={12}>
                            {/* <Row>
                                <Col className='text-end'>
                                    <Button variant="outline-primary" type='button' size='sm' onClick={() => selectionDone('exportfile', processid)} >Export</Button>
                                </Col>
                            </Row> */}
                            <Row >
                                <Col sm={1} >
                                    <div className="sticky-div float-right">
                                        <button className="p-2 mb-1 bg-white rounded shadow-sm hover:bg-gray-50 border border-gray-200" title="Export" onClick={() => selectionDone('exportfile', processid)}>
                                            <FontAwesomeIcon icon={faShareSquare} size='xs' />
                                        </button>
                                        <button className="p-2 mb-1 bg-white rounded shadow-sm hover:bg-gray-50 border border-gray-200" title="Delete" onClick={() => selectionDone('showlist',0)}>
                                            <FontAwesomeIcon icon={faTrashAlt} size='sm' />
                                        </button>
                                    </div>
                                </Col>
                                <Col sm={11} >
                                    <TranscriptDisplay segments={data} />
                                </Col>
                            </Row>
                        </Col>
                    }
                </Row>
            </Container>
        </>
    );
};

export default ShowTranscript;
