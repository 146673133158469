import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { post } from '../../services/apiServices';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider';
import PublicFooter from '../../components/footer';

interface VerificationProps {
    // Add props if needed
}
interface VerifyResponse {
    data: {
        success: boolean;
        message: string;
        name: string;
        id: number;
        token: string;
    };
}

const VerifyRegistration: React.FC<VerificationProps> = () => {
    const [verificationCode, setVerificationCode] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [processing, setProcessing] = useState(false);
    const navigate = useNavigate();
    const { user } = useAuth();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setProcessing(true)
        setError(null)
        try {
            const response: VerifyResponse = await post('/verify', { verificationCode: verificationCode }, user ? user?.token : null);
            if (response.data.success == false) {
                setError(response.data.message)
                setProcessing(false)
            } else {
                localStorage.setItem('user', JSON.stringify(response.data));
                navigate('/home')
            }
        } catch (error: any) {
            setError(error.response.data.message);
        }
    };

    return (
        <>
            <Container className='main-container'>
                <Row className="justify-content-md-center pt-5">
                    <Col md={4}>
                        <h2 className="text-center">Verify</h2>
                        <p>
                            Please check your email, a verification code has been sent to you. Enter that code here and click verify to continue.
                        </p>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formUsername">
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="Verification Code"
                                    value={verificationCode}
                                    onChange={(e) => setVerificationCode(e.target.value)}
                                />
                            </Form.Group>
                            {error && <Alert variant="danger">{error}</Alert>}

                            <Button variant="primary" type="submit" className="w-100" disabled={processing}>
                                {processing &&
                                    <FontAwesomeIcon icon={faSpinner} className="rotate" size='2x' style={{ marginRight: '12px' }} />
                                }
                                {!processing &&
                                    <>Verify</>
                                }
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <PublicFooter />
        </>
    );
};

export default VerifyRegistration;