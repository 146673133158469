import React, { useState, useEffect, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';

const PublicFooter: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <footer id="footer" className="footer position-relative light-background">

                <div className="container footer-top">
                    <div className="row gy-4">
                        <div className="col-lg-4 col-md-6 footer-about">
                            <a href="/home" className="logo d-flex align-items-center">
                                <span className="sitename">Transcribo</span>
                            </a>
                            <div className="footer-contact pt-3">
                                <p>229 Arguile, Mossel Bay Golf Estate</p>
                                <p>Mossel Bay, 6506</p>
                                <p className="mt-3"><strong>Phone:</strong> <span>+27 81 345 7008</span></p>
                                <p><strong>Email:</strong> <span>ralphklein72@gmail.com</span></p>
                            </div>
                            <div className="social-links d-flex mt-4">
                                <a ><i className="bi bi-twitter-x"></i></a>
                                <a ><i className="bi bi-facebook"></i></a>
                                <a><i className="bi bi-instagram"></i></a>
                                <a ><i className="bi bi-linkedin"></i></a>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><a href="#">About us</a></li>
                                <li><a href="#">Services</a></li>
                                <li><a href="/terms">Terms of service</a></li>
                                <li><a href="/privacy">Privacy policy</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-2 col-md-3 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li><a href="#">Legal Transcription</a></li>
                                <li><a href="#">Business Meeting Transcription</a></li>
                                <li><a href="#">Podcast Transcription</a></li>
                                <li><a href="#">Medical Transcription</a></li>
                                <li><a href="#">Focus Group Transcription</a></li>
                            </ul>
                        </div>



                    </div>
                </div>

                <div className="container copyright text-center mt-4">
                    <p>© <span>Copyright</span> <strong className="px-1 sitename">Codebin Consulting Services Pty (Ltd)</strong><span>All Rights Reserved</span></p>

                </div>

            </footer>

            <a href="#" id="scroll-top" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
        </>
    );
};

export default PublicFooter;
