import React, { createContext, useContext, useState, ReactNode } from 'react';
export interface User {
  user: UserDetail;
  message: string;
  success: boolean;
}

export interface UserDetail {
  token: string;
  credits: string;
  id: number;
  name: string;
}

export interface Status {
  plan: string;
  status: string;
}

interface AuthContextType {
  user: UserDetail | null;
  userstatus: Status | null;
}

const isAuthenticated = (): UserDetail | null => {
  try {
    const user = JSON.parse(localStorage.getItem('user') || '');
    if (user && user.success) {
      const castUser = {
        token: user.token,
        credits: user.user.credits,
        id: user.user.id,
        name: user.user.name
      }
      return castUser;
    }
  } catch (error) {
    console.error('Error parsing user data:', error);
  }
  return null;
};

const getUserStatus = (): Status | null => {
  try {
    const status = JSON.parse(localStorage.getItem('status') || '');
    if (status && status.status) {
      return status;
    }
  } catch (error) {
    console.error('Error parsing userstatus data:', error);
  }
  return null;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<UserDetail | null>(isAuthenticated());
  const [userstatus, setUserstatus] = useState<Status | null>(getUserStatus());
  // const userstatus = null
  // try {
  //   const userstatus = JSON.parse(localStorage.getItem('status') || '');
  // } catch (err) {
  // }

  return (
    <AuthContext.Provider value={{ user, userstatus }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};