import React, { useState } from 'react';
import { Container, Row, Col, Button, Nav, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../context/AuthProvider';
import Clientheader from '../../components/clientheader';
import FileUpload from '../../components/upload';
import ProcessingComponent from '../../components/processingcomponent';
import Transcriptionresult from '../../components/transcriptionresult';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper, faExclamationTriangle, faTable } from '@fortawesome/free-solid-svg-icons';
import CreatingTranscript from '../../components/createtranscript';
import ShowTranscript from '../../components/showtranscript';
import TranscriptionList from '../../components/transcriptionslist';
import DocumentTypeSelector from '../../components/documenttype/documenttype';
import './style.css'

const Home = () => {
  const { user, userstatus } = useAuth();
  const [page, setPage] = useState(0); // 0
  const [processId, setProcessId] = useState('');
  const [userInfo, setUserInfo] = useState(user);
  const navigate = useNavigate();

  const doneWithUplaod = (e: any) => {
    setProcessId(e.id)
    setPage(1)
  };

  const doneWithProcessingAudio = (res: string, action: any) => {
    switch (res) {
      case 'success':
        setPage(2);
        break;
      case 'error':
        setPage(4);
        break;
      case 'transcribed':
        setPage(3);
        break;
      case 'donemappingspeakers':
        setPage(5);
        break;
      case 'selectionMade':
        setProcessId(action);
        setPage(6);
        break;
      case 'done':
        setPage(6);
        break;
      case 'showlist':
        setPage(7);
        break;
      case 'exportfile':
        setPage(8);
        setProcessId(action);
        break;
      case 'notokens':
        setPage(9);
    }
  };

  React.useEffect(() => {
    if (userstatus && userstatus.status === 'wanttopay') {
      console.log('Attempting to navigate to payments. Status:', userstatus.status);
      navigate(`/payments?plan=${userstatus.plan}`);
    } else {
      console.log('Not navigating. Current status:', userstatus?.status);
    }
  }, [userstatus, navigate]);

  const gotoStart = () => {
    navigate('/documentproc');
  };

  return (
    <div className="d-flex flex-column vh-100">
      {/* <Alert style={{padding: '0px', borderRadius: '0px'}} variant="danger">We're excited to have you explore our website! Right now, we're in the beta testing phase, which means we're still fine-tuning things to ensure the best experience for you.</Alert> */}
      {/* Top Menu - Fixed */}
      <Clientheader />

      {/* Main Content Area */}
      <Container fluid className="flex-grow-1 d-flex" style={{ marginTop: '50px' }}>
        <Row className="flex-grow-1">
          {/* Left Menu Panel - Fixed */}
          <Col md={3} lg={2} className="p-2 position-fixed left-menu-panel left-menu" >
            <div className='d-flex left-menu-specer justify-content-center'>
              <a href="/" className="logo d-flex align-items-center">
                <img src="assets/img/logo_main.png" alt="" className='img-fluid' />
              </a>
            </div>
            <Button variant="outline-primary" type="button" size="sm" onClick={() => setPage(0)} className='w-100 mb-2'>
              <FontAwesomeIcon icon={faNewspaper} /> New transciption
            </Button>
            <Button variant="outline-primary" type="button" size="sm" onClick={() => setPage(7)} className='w-100 mb-2'>
              <FontAwesomeIcon icon={faTable} /> Transcriptions
            </Button>
          </Col>

          {/* Spacer Column */}
          <Col md={3} lg={2} className='position-fixed d-flex left-menu-column' >
            <div className="align-self-end left-user-info">
              <p className='mb-0'>{userInfo?.name}</p>
              <p>Credits: {userInfo?.credits ? Number(userInfo.credits).toFixed(2) : '0.00'}</p>
            </div>
          </Col>

          {/* Content Area - Scrollable */}
          <Col md={9} lg={10} className="p-3 content-area" >

            <Row className="justify-content-md-center pt-1">
              {page == 0 &&
                <Col md={6} sm={10} className="text-center" >
                  <h1>Upload file</h1>
                  <FileUpload doneUpload={doneWithUplaod} />
                </Col>
              }
              {page == 1 &&
                <Col md={10} className="text-center" >
                  <ProcessingComponent processid={processId} doneAudio={doneWithProcessingAudio} />
                </Col>
              }
              {page == 2 &&
                <Col md={6} className="text-center">
                  <Transcriptionresult processid={processId} doneAudio={doneWithProcessingAudio} />
                </Col>
              }
              {page == 3 &&
                <Col md={6} className="text-center">
                  <ProcessingComponent processid={processId} doneAudio={doneWithProcessingAudio} />
                </Col>
              }
              {page == 4 &&
                <Col md={6} className="text-center">
                  <FontAwesomeIcon icon={faExclamationTriangle} size='2x' style={{ marginRight: '12px' }} />
                  Something went wrong...
                </Col>
              }
              {page == 5 &&
                <Col md={6} className="text-center">
                  <CreatingTranscript processid={processId} doneAudio={doneWithProcessingAudio} />
                </Col>
              }
              {page == 6 &&
                <Col md={10} className="text-center">
                  <ShowTranscript processid={processId} selectionDone={doneWithProcessingAudio} />
                </Col>
              }
              {page == 7 &&
                <Col md={10} className="text-center">
                  <TranscriptionList user={userInfo} selectionDone={doneWithProcessingAudio} />
                </Col>
              }
              {page == 8 &&
                <Col md={10} className="text-center">
                  <DocumentTypeSelector processid={processId} user={userInfo} selectionDone={doneWithProcessingAudio} />
                </Col>
              }
              {page == 9 &&
                <Col md={10} className="text-center">
                  <p>You do not have enough tokens to complete this transcription</p>
                </Col>
              }
            </Row>

          </Col>
        </Row>
      </Container>

    </div >
  );
};

export default Home;
