import React, { useEffect, useRef } from 'react';
import { post } from '../../services/apiServices';

interface GoogleLoginProps {
  clientId: string;
  onSuccess: (response: any) => void;
  onFailure: (error: any) => void;
}

declare global {
  interface Window {
    google?: {
      accounts: {
        id: {
          initialize: (input: any) => void;
          renderButton: (element: HTMLElement, options: any) => void;
        };
      };
    };
  }
}

const GoogleLogin: React.FC<GoogleLoginProps> = ({ clientId, onSuccess, onFailure }) => {
  const googleButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      initializeGoogleSignIn();
    };

    script.onerror = () => {
      console.error('Failed to load Google Identity Services script');
      onFailure('Script loading failed');
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [clientId, onSuccess, onFailure]);

  const initializeGoogleSignIn = () => {
    if (window.google && window.google.accounts && window.google.accounts.id) {
      window.google.accounts.id.initialize({
        client_id: clientId,
        callback: handleCredentialResponse,
        auto_select: false,
        cancel_on_tap_outside: true,
      });

      if (googleButtonRef.current) {
        window.google.accounts.id.renderButton(
          googleButtonRef.current,
          { theme: 'outline', size: 'large', type: 'standard' }
        );
      } else {
        console.error('Button ref is null');
      }
    } else {
      console.error('Google Identity Services failed to load properly');
      onFailure('Google Identity Services not available');
    }
  };

  const handleCredentialResponse = async (response: any) => {
    console.log('Received credential response');
    if (response.credential) {
      try {
        const backendResponse = await post('/auth/google', {
          token: response.credential
        },'');
 
        localStorage.setItem('user', JSON.stringify(backendResponse.data));
        onSuccess("user logged in");
      } catch (error) {
        console.error('Backend authentication failed', error);
        onFailure('Backend authentication failed');
      }
    } else {
      onFailure('Login Failed: No credential received');
    }
  };

  return <div ref={googleButtonRef}></div>;
};

export default GoogleLogin;