import React, { useState } from 'react';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PublicFooter from '../../components/footer';

const Landing: React.FC = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(-1);

  const gotoStart = () => {
    navigate('/documentproc');
  };

  const handleLogout = () => {
    localStorage.removeItem('auth');
    navigate('/login');
  };

  const faqData = [
    {
      question: 'What types of files can I upload for transcription?',
      answer: 'Our platform supports a wide range of file formats, including MP3, WAV, MP4, AVI, and M4A. If you have a specific file format not listed, please contact our support team.',
    },
    {
      question: 'How accurate is your transcription service?',
      answer: 'Our AI-powered transcription service achieves high accuracy rates, typically above 90%. However, accuracy may vary depending on factors like audio quality, dialects, and technical terminology.',
    },
    {
      question: 'Can I request human review or editing of my transcripts?',
      answer: 'Yes, we offer optional human review and editing services to ensure maximum accuracy. This is particularly useful for critical applications, such as research, legal documentation, or media production. This comes at an additional charge and takes longer.',
    },
    {
      question: 'How long does transcription typically take?',
      answer: 'Transcription times vary depending on file length and complexity. Our automated system typically processes files within minutes to hours. For larger files or human-reviewed transcripts, turnaround times may take longer.',
    },
    {
      question: 'Is my data secure and confidential?',
      answer: 'Absolutely! Our platform employs robust security measures, including encryption, secure servers, and strict access controls. We adhere to industry standards for data protection and confidentiality. All files are kept on the server for the duration of the transcript and then destroyed. We keep no records of your information.',
    },
    {
      question: 'Can I integrate your transcription service with my existing workflow or platform?',
      answer: 'Yes, we provide APIs and customizable integration options to seamlessly integrate our transcription service with your existing workflow, platform, or software. Contact our development team to discuss your specific requirements.',
    },
  ];

  return (
    <>

      <main className="main">
        {/* <Alert style={{ padding: '0px', borderRadius: '0px' }} variant="danger">We're excited to have you explore our website! Right now, we're in the beta testing phase, which means we're still fine-tuning things to ensure the best experience for you.</Alert> */}
        <header id="header" className="header d-flex align-items-center fixed-top">
          <div className="container-fluid container-xl position-relative d-flex align-items-center">

            <a href="/" className="logo d-flex align-items-center me-auto">
              <img src="assets/img/logo.png" alt="" />
              <h1 className="sitename">Transcribo</h1>
            </a>

            <nav id="navmenu" className="navmenu">
              <ul>
                <li><a href="#hero" className="active">Home</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#services">Services</a></li>
                <li><a href="#pricing">Pricing</a></li>
                <li><a href="#faq">FAQ</a></li>
              </ul>
              <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
            </nav>

            <a className="btn-getstarted" href="/home">Get Started</a>

          </div>
        </header>

        <section id="hero" className="hero section">
          <div className="hero-bg">
            <img src="assets/img/hero-bg-light.webp" alt="" />
          </div>
          <div className="container text-center">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h1 data-aos="fade-up">Welcome to <span>Transcribo</span></h1>
              <p data-aos="fade-up" data-aos-delay="100">Quickly start your project now and set the stage for success<br /></p>
              <img src="assets/img/hero-services-img2.webp" className="img-fluid hero-img" alt="" data-aos="zoom-out" data-aos-delay="300" />
              {/* <img src="assets/img/hero-services-img.webp" className="img-fluid hero-img" alt="" data-aos="zoom-out" data-aos-delay="300" /> */}
            </div>
          </div>
        </section>

        <section id="featured-services" className="featured-services section light-background">

          <div className="container">

            <div className="row gy-4">

              <div className="col-xl-4 col-lg-6" data-aos="fade-up" data-aos-delay="100">
                <div className="service-item d-flex">
                  <div className="icon flex-shrink-0"><i className="bi bi-briefcase"></i></div>
                  <div>
                    <h4 className="title"><a href="#" className="stretched-link">Business Meetings</a></h4>
                    <p className="description">Transcribing business meetings, conferences, or interviews helps companies maintain detailed records of discussions, decisions, and action plans. It enhances communication by ensuring clarity, provides a reference for employees who were absent, and supports legal or compliance documentation when required in corporate environments.</p>
                  </div>
                </div>
              </div>


              <div className="col-xl-4 col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <div className="service-item d-flex">
                  <div className="icon flex-shrink-0"><i className="bi bi-card-checklist"></i></div>
                  <div>
                    <h4 className="title"><a href="#" className="stretched-link">Legal Transcriptions</a></h4>
                    <p className="description">In legal proceedings, accurate transcriptions of courtroom dialogue are essential. Transcribers convert recorded testimonies, hearings, and legal arguments into written documents, preserving a clear record. These transcriptions ensure that court records are accessible for future reference, appeals, and legal research.</p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-6" data-aos="fade-up" data-aos-delay="300">
                <div className="service-item d-flex">
                  <div className="icon flex-shrink-0"><i className="bi bi-capsule-pill"></i></div>
                  <div>
                    <h4 className="title"><a href="#" className="stretched-link">Medical Transcription</a></h4>
                    <p className="description">Healthcare professionals use transcription services to convert doctors' spoken notes into written medical records. This ensures that patient histories, diagnoses, and treatment plans are properly documented. Medical transcription plays a crucial role in maintaining accurate, up-to-date patient files, which supports quality care and compliance with healthcare regulations.</p>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </section>

        <section id="about" className="about section">

          <div className="container">

            <div className="row gy-4">

              <div className="col-lg-6 content" data-aos="fade-up" data-aos-delay="100">
                <p className="who-we-are">Who We Are</p>
                <h3>Accurate, Fast, and Reliable Transcription Services</h3>
                <p className="fst-italic">
                  Transforming audio into text with precision and efficiency
                </p>
                <ul>
                  <li><i className="bi bi-check-circle"></i> <span>High-Quality Transcriptions for Legal, Medical, and Business Needs</span></li>
                  <li><i className="bi bi-check-circle"></i> <span>Fast Turnaround Time with Guaranteed Accuracy</span></li>
                  <li><i className="bi bi-check-circle"></i> <span>Easy File Upload and Secure Processing</span></li>
                </ul>
                {/* <a href="#" className="read-more"><span>Read More</span><i className="bi bi-arrow-right"></i></a> */}
              </div>

              <div className="col-lg-6 about-images" data-aos="fade-up" data-aos-delay="200">
                <div className="row gy-4">
                  <div className="col-lg-6">
                    <img src="assets/img/about-company-1.jpg" className="img-fluid" alt="" />
                  </div>
                  <div className="col-lg-6">
                    <div className="row gy-4">
                      <div className="col-lg-12">
                        <img src="assets/img/about-company-2.jpg" className="img-fluid" alt="" />
                      </div>
                      <div className="col-lg-12">
                        <img src="assets/img/about-company-3.jpg" className="img-fluid" alt="" />
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>
        </section>


        <section id="services" className="services section light-background">


          <div className="container section-title" data-aos="fade-up">
            <h2>Services</h2>
            <p>These are some of the services we provide.</p>
          </div>

          <div className="container">

            <div className="row g-5">

              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                <div className="service-item item-cyan position-relative">
                  <i className="bi bi-bank icon"></i>
                  <div>
                    <h3>Legal Transcription</h3>
                    <p>Accurately transcribe courtroom sessions, depositions, and legal consultations with speaker identification, ensuring a clear record for legal documentation and case analysis.</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <div className="service-item item-orange position-relative">
                  <i className="bi bi-capsule-pill icon"></i>
                  <div>
                    <h3>Medical Transcription</h3>
                    <p>Convert medical dictations, patient notes, and consultations into detailed written records, enabling healthcare professionals to maintain precise patient files while saving time on manual documentation.</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="300">
                <div className="service-item item-teal position-relative">
                  <i className="bi bi-easel icon"></i>
                  <div>
                    <h3>Business Meeting Transcription</h3>
                    <p>Document corporate meetings, conference calls, and team discussions, providing organized, searchable transcripts with speaker differentiation for better record-keeping and accountability.</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="400">
                <div className="service-item item-red position-relative">
                  <i className="bi bi-bounding-box-circles icon"></i>
                  <div>
                    <h3>Interview Transcription</h3>
                    <p>Transcribe interviews for research, media, or recruitment purposes, capturing every speaker's contribution with diarization for clear, structured content ready for reporting or analysis.</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="500">
                <div className="service-item item-indigo position-relative">
                  <i className="bi bi-mic icon"></i>
                  <div>
                    <h3>Podcast Transcription</h3>
                    <p>Turn podcasts or webinars into written content with speaker labels, making them accessible for broader audiences, including those with hearing impairments, and optimizing for search engines.</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="600">
                <div className="service-item item-pink position-relative">
                  <i className="bi bi-chat-square-text icon"></i>
                  <div>
                    <h3>Focus Group Transcription</h3>
                    <p>Convert group discussions into structured documents with identified speakers, allowing for easier analysis of research data, marketing feedback, or public opinion studies.</p>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </section>



        <section id="pricing" className="pricing section">

          <div className="container section-title" data-aos="fade-up">
            <h2>Pricing</h2>
            <p>Find the perfect plan that meets your transcription needs. All plans offer secure, accurate, and fast AI transcription services.</p>
          </div>

          <div className="container">

            <div className="row gy-4">

              <div className="col-lg-4" data-aos="zoom-in" data-aos-delay="100">
                <div className="pricing-item">
                  <h3>Free Plan</h3>
                  <p className="description">For light users who need occasional transcription, or if you just want to try it out.</p>
                  <h4><sup>R</sup>0<span> / month</span></h4>
                  <a href="/register" className="cta-btn">Start a free trial</a>
                  <ul>
                    <li><i className="bi bi-check"></i> <span>60 free tokens included<br /> (1 token = 1 minute of transcription)</span></li>
                    <li><i className="bi bi-check"></i> <span>Standard transcription features</span></li>
                    <li><i className="bi bi-check"></i> <span>R0.35 per token, pay as you go</span></li>
                    <li><i className="bi bi-check"></i> <span>Basic speaker diarization</span></li>
                    <li className="na"><i className="bi bi-x"></i> <span>No priority support</span></li>
                    <li className="na"><i className="bi bi-x"></i> <span>No access to advanced features</span></li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-4" data-aos="zoom-in" data-aos-delay="200">
                <div className="pricing-item featured">
                  <p className="popular">Popular</p>
                  <h3>Business Plan</h3>
                  <p className="description">For regular users requiring affordable transcription services.</p>
                  <h4><sup>R</sup>150<span> / month</span></h4>
                  <a href="/payments?plan=business" className="cta-btn">Start now</a>
                  <ul>
                    <li><i className="bi bi-check"></i> <span>Includes 600 tokens<br /> (1 token = 1 minute of transcription)</span></li>
                    <li><i className="bi bi-check"></i> <span>Speaker diarization and time-stamping</span></li>
                    <li><i className="bi bi-check"></i> <span>Discounted token rate: R0.25 per token for additional minutes</span></li>
                    <li><i className="bi bi-check"></i> <span>Priority support included</span></li>
                    <li><i className="bi bi-check"></i> <span>Access to multiple file formats</span></li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-4" data-aos="zoom-in" data-aos-delay="300">
                <div className="pricing-item">
                  <h3>Platinum Plan</h3>
                  <p className="description">For power users needing bulk transcription with premium features</p>
                  <h4><sup>R</sup>240<span> / month</span></h4>
                  <a href="/payments?plan=platinum" className="cta-btn">Start now</a>
                  <ul>
                    <li><i className="bi bi-check"></i> <span>Includes 1200 tokens<br /> (1 token = 1 minute of transcription)</span></li>
                    <li><i className="bi bi-check"></i> <span>Advanced speaker diarization and time-stamping</span></li>
                    <li><i className="bi bi-check"></i> <span>Discounted token rate: R0.20 per token for additional minutes</span></li>
                    <li><i className="bi bi-check"></i> <span>Priority support</span></li>
                    <li><i className="bi bi-check"></i> <span>Access to all supported file formats</span></li>
                    <li><i className="bi bi-check"></i> <span>Custom-trained transcription model for your organization</span></li>
                  </ul>
                </div>
              </div>

            </div>

          </div>

        </section>

        <section id="featured-services" className="featured-services section light-background">

          <div className="container">

            <div className="row gy-4">

              <div className="col-xl-4 col-lg-6" data-aos="fade-up" data-aos-delay="100">
                <div className="service-item d-flex">
                  <div className="icon flex-shrink-0"><i className="bi bi-hourglass"></i></div>
                  <div>
                    <h4 className="title"><a href="#" className="stretched-link">Efficiency and Time-Saving</a></h4>
                    <p className="description">Automate tedious transcription tasks with our AI-powered services, freeing up time for more critical tasks. Fast turnaround times ensure quick access to transcripts, enabling swift decision-making and streamlined workflows</p>
                  </div>
                </div>
              </div>


              <div className="col-xl-4 col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <div className="service-item d-flex">
                  <div className="icon flex-shrink-0"><i className="bi bi-umbrella"></i></div>
                  <div>
                    <h4 className="title"><a href="#" className="stretched-link">Accuracy and Reliability</a></h4>
                    <p className="description">Ensure high accuracy transcripts with our advanced AI technology, minimizing errors and revisions. Reliable outputs enable confidence in using transcripts for critical applications, such as research, media production, or legal documentation.</p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-6" data-aos="fade-up" data-aos-delay="300">
                <div className="service-item d-flex">
                  <div className="icon flex-shrink-0"><i className="bi bi-piggy-bank"></i></div>
                  <div>
                    <h4 className="title"><a href="#" className="stretched-link">Cost-Effectiveness and Scalability</a></h4>
                    <p className="description">Reduce transcription costs with our AI-driven services, ideal for large volumes or ongoing projects. Scalable solutions adapt to varying demands, providing affordable and efficient transcription without compromising quality or security.</p>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </section>

        <section id="faq" className="faq section">


          <div className="container section-title" data-aos="fade-up">
            <h2>Frequently Asked Questions</h2>
          </div>

          <div className="container">

            <div className="row justify-content-center">

              <div className="col-lg-10" data-aos="fade-up" data-aos-delay="100">

                <div className="faq-container">
                  {faqData.map((faq, index) => (
                    <div
                      key={index}
                      className={`faq-item ${activeIndex === index ? 'faq-active' : ''}`}
                      onClick={() => setActiveIndex(index)}
                    >
                      <h3>{faq.question}</h3>
                      <div className="faq-content">
                        <p>{faq.answer}</p>
                      </div>
                      <i className="faq-toggle bi bi-chevron-right"></i>
                    </div>
                  ))}
                </div>

              </div>

            </div>

          </div>

        </section>

        <PublicFooter />

      </main>
    </>
  );
};

export default Landing;
