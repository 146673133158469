import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Table } from 'react-bootstrap';
import { TranscriptResponse } from '../../interfaces';
import { get } from '../../services/apiServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { format, parseISO } from 'date-fns';

interface TranscriptionListProps {
    user: any;
    selectionDone: any;
}

const TranscriptionList: React.FC<TranscriptionListProps> = ({ user, selectionDone }) => {
    const [userId, setUserId] = useState(user.id);
    const [data, setData] = useState<any>('');
    const [page, setPage] = useState<number>(0);

    useEffect(() => {
        console.log(user)
        const fetchData = async () => {
            try {
                const response = await get('/fetchtranscriptbyuser/' + userId, user ? user?.token : null);
                const pollResponse = response.data as TranscriptResponse;
                setData(pollResponse.data)
                setPage(1)
            } catch (err) {
                console.log('Error fetching data');
            }
        };
        fetchData()
    }, []);

    const formatDateFns = (dateString: string): string => {
        const date = parseISO(dateString);
        return format(date, "MMMM d, yyyy 'at' h:mm a");
    };

    return (
        <>
            <h3>Transcriptions</h3>
            <p>This is a list of your transcriptions with status values</p>
            <Row >
                {page === 0 &&
                    <Col md={12}>
                        <FontAwesomeIcon icon={faSpinner} className="rotate" size='2x' style={{ marginRight: '12px' }} />
                        <div>
                            <p>Loading .....</p>
                        </div>
                    </Col>
                }

                {page === 1 && (
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th>Transaction #</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((segment: any, index: number) => (
                                <tr key={index}>
                                    <td>{segment.key || 'N/A'}</td>
                                    <td>{segment.date ? formatDateFns(segment.date) : 'N/A'}</td>
                                    <td>{segment.status || 'N/A'}</td>
                                    <td>
                                        {segment.status === 'done' &&
                                            <Button variant="secondary" type="button" size="sm" onClick={() => selectionDone('selectionMade', segment.key)}>
                                                <FontAwesomeIcon icon={faEye} />
                                            </Button>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </Row>
        </>
    );
};

export default TranscriptionList;
