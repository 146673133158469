import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { upload } from '../../services/apiServices';
import { useAuth } from '../../context/AuthProvider';
import Clientheader from '../../components/clientheader';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface FileUploadProps {
    doneUpload: any;
}

const FileUpload: React.FC<FileUploadProps> = ({ doneUpload }) => {
    const [file, setFile] = useState<File | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [isUploading, setIsUploading] = useState(false);
    const { user } = useAuth();
    const navigate = useNavigate();

    const MAX_SIZE = 300 * 1024 * 1024; // 300 MB
    const ALLOWED_TYPES = ['audio/mpeg', 'audio/wav', 'audio/x-wav', 'audio/wave'];

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0] || null;
        if (selectedFile) {
            if (!ALLOWED_TYPES.includes(selectedFile.type)) {
                setError('Please upload a .wmv, .wav or .mp3 file.');
                setFile(null);
                setIsUploading(false);
                return;
            }
            if (selectedFile.size > MAX_SIZE) {
                setError('File size must be less than 300MB.');
                setFile(null);
                setIsUploading(false);
                return;
            }
            setFile(selectedFile);
        }
    };

    const handleUpload = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!file) {
            setError('Please select a file to upload.');
            return;
        }
        setIsUploading(true)
        const formData = new FormData();
        formData.append('file', file);

        try {
            setError(null);
            setSuccess(null);
            const response = await upload<{ message: string }>('/upload', formData, user ? user?.token : null);
            if (response.error == "Invalid token") {
                localStorage.removeItem('user');
                navigate('/login')
            }
            if (response.success) {
                setSuccess(`File "${file.name}" uploaded successfully.`);
                doneUpload(response.data)
            } else {
                setError('File upload failed.');
                setIsUploading(false);
            }
        } catch (err) {
            setIsUploading(false);
            setError('An error occurred during file upload.');
        }
    };

    return (
        <>
            <Clientheader />
            <Container>
                <Row >
                    <Col md={12}>
                        {error && <Alert variant="danger">{error}</Alert>}
                        {success && <Alert variant="success">{success}</Alert>}
                        <Form onSubmit={handleUpload}>
                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label>Select a document. .mp3, .wmv, or .wav</Form.Label>
                                <Form.Control
                                    type="file"
                                    accept=".mp3,.wmv,.wav"
                                    onChange={handleFileChange}
                                />
                            </Form.Group>
                            {file && (
                                <Alert variant="info">
                                    Selected file: <strong>{file.name}</strong>
                                </Alert>
                            )}
                            {/* <Button variant="primary" type="submit" className="w-100" disabled={isUploading}>
                                Upload and transcribe
                            </Button> */}
                            <p className='text-danger'>By continuing, you acknowledge that the accuracy of the transcription provided is dependent on the quality of the audio you submit. While we strive to deliver the best possible results, it is your responsibility to review the transcription for errors and make any necessary corrections. We cannot guarantee 100% accuracy and are not liable for any inaccuracies or omissions in the final transcript.</p>
                            <Button variant="primary" type="submit" className="w-100" disabled={isUploading}>
                                {isUploading &&
                                    <>
                                        <FontAwesomeIcon icon={faSpinner} className="rotate" size='2x' />
                                    </>
                                }
                                {!isUploading &&
                                    <>Upload and transcribe</>
                                }
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default FileUpload;
