import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import PlainHeader from '../../components/plainheader';
import { useAuth } from '../../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
// import PaymentIframe from '../../components/paymentiframe';

const PaymentPage = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [plan, setPlan] = useState<string>('');
    const [planUrl, setPlanUrl] = useState<string>('');
    const { user } = useAuth();

    const queryParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        const planchosen = queryParams.get('plan');
        if (planchosen) {
            if (planchosen == 'business') {
                setPlanUrl('https://paystack.com/pay/trancribobasic')
            }
            if (planchosen == 'platinum') {
                setPlanUrl('https://paystack.com/pay/trancriboplatinum')
            }
            if(planchosen=='test'){
                setPlanUrl('https://paystack.com/pay/transcribo-plans')
            }

            setPlan(planchosen)
            if (user) {
                setIsLoggedIn(true)
                localStorage.removeItem("status")
            }
            else {
                localStorage.setItem("status", JSON.stringify({ plan: plan, status: 'wanttopay' }))
            }
        }
    }, []);

    return (
        <>
            <PlainHeader />
            <section id="pricing" className="pricing">
                <Container className='main-container mt-5'>
                    <Row className="justify-content-md-center ">
                        <Col md={12}>
                            <Row>
                                <Col md={5}>
                                    {plan === 'business' &&
                                        <div className="pricing-item featured">
                                            <p className="popular">Popular</p>
                                            <h3>Business Plan</h3>
                                            <p className="description">For regular users requiring affordable transcription services.</p>
                                            <h4><sup>R</sup>150<span> / month</span></h4>
                                            <ul>
                                                <li><i className="bi bi-check"></i> <span>Includes 600 tokens<br /> (1 token = 1 minute of transcription)</span></li>
                                                <li><i className="bi bi-check"></i> <span>Speaker diarization and time-stamping</span></li>
                                                <li><i className="bi bi-check"></i> <span>Discounted token rate: R0.25 per token for additional minutes</span></li>
                                                <li><i className="bi bi-check"></i> <span>Priority support included</span></li>
                                                <li><i className="bi bi-check"></i> <span>Access to multiple file formats</span></li>
                                            </ul>
                                        </div>
                                    }
                                    {plan === 'platinum' &&
                                        <div className="pricing-item">
                                            <h3>Platinum Plan</h3>
                                            <p className="description">For power users needing bulk transcription with premium features</p>
                                            <h4><sup>R</sup>240<span> / month</span></h4>
                                            <ul>
                                                <li><i className="bi bi-check"></i> <span>Includes 1200 tokens<br /> (1 token = 1 minute of transcription)</span></li>
                                                <li><i className="bi bi-check"></i> <span>Advanced speaker diarization and time-stamping</span></li>
                                                <li><i className="bi bi-check"></i> <span>Discounted token rate: R0.20 per token for additional minutes</span></li>
                                                <li><i className="bi bi-check"></i> <span>Priority support</span></li>
                                                <li><i className="bi bi-check"></i> <span>Access to all supported file formats</span></li>
                                                <li><i className="bi bi-check"></i> <span>Custom-trained transcription model for your organization</span></li>
                                            </ul>
                                        </div>
                                    }
                                </Col>
                                <Col md={7} className='text-center' >
                                    {!isLoggedIn &&
                                        <>
                                            <Row>
                                                <Col>
                                                    Thank you for choosing us as your transcription partner, in order to process a payment you have to be logged.
                                                    If you do not already have an account with us you can register here, it only takes a few minutes.
                                                </Col>
                                            </Row>
                                            <Row className='mb-5'>
                                                <Col sm={6}>
                                                    <Button onClick={() => navigate('/login')} className="mt-3 w-100">
                                                        Login
                                                    </Button>
                                                </Col>
                                                <Col sm={6}>
                                                    <Button onClick={() => navigate('/register')} className="mt-3 w-100">
                                                        Register
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12}>
                                                    <img src="assets/img/picb.png" className="img-fluid" alt="" />
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    {isLoggedIn &&
                                        <>
                                            <h3>Paystack payment gateway</h3>
                                            <p>We make use of a payment gatewat called PayStack, when you click on the button below you will be redirected to a sucure paymant portal, but don't worry, after payment you will be brought back here.</p>

                                            <a href={planUrl} className="cta-btn">Payment now</a>
                                        </>
                                    }
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container >
            </section>

        </>
    );
};

export default PaymentPage;