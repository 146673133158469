import React, { useState, useEffect, ChangeEvent } from 'react';
import { Form, Button, Container, Row, Col, Alert, InputGroup, Accordion } from 'react-bootstrap';
import { get, post } from '../../services/apiServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import './style.css';
import { ClipsResponse } from '../../interfaces';
import { useAuth } from '../../context/AuthProvider';
import Clientheader from '../../components/clientheader';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'ocrapola';

interface FileUploadProps {
    processid: any;
    doneAudio: any;
}

interface ClipItem {
    id: number;
    speaker: string;
    filename: string;
    text: string;
}

const Transcriptionresult: React.FC<FileUploadProps> = ({ processid, doneAudio }) => {
    const [payload, setPayload] = useState<ClipsResponse>();
    const [inputValues, setInputValues] = useState<Record<string, string>>({});
    const { user } = useAuth();

    // Step 2: Handle input change
    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
        setInputValues(prevValues => ({
            ...prevValues,
            [id]: e.target.value
        }));
    };

    const updateSpeaker = async (id: number) => {
        const inputValue = inputValues[id];
        const response = await post('/updateClipSpeaker', { id: id, value: inputValue }, user ? user?.token : null);
        toast.success('New name updated!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await get('/getaudioclips/' + processid, user ? user?.token : null);
                const pollResponse = response.data as ClipsResponse;
                setPayload(pollResponse.data);
                // localStorage.setItem("key", pollResponse);
                console.log(pollResponse.data)
            } catch (err) {
                console.log('Error fetching data');
            }
        };

        fetchData()
    }, []);

    const mappedClips = payload ? Object.entries(payload).map(([speaker, item]: [string, ClipItem]) => (
        <Col md={12}>

            <div key={speaker} className='clip-box'>
                <Row >
                    <Col xs={10}>
                        <InputGroup size="sm" className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-sm"><b>{item.speaker}</b></InputGroup.Text>
                            <Form.Control
                                aria-label="Small"
                                aria-describedby="inputGroup-sizing-sm"
                                value={inputValues[item.id] || ''} // Bind value to state
                                onChange={(e) => handleInputChange(e, item.id)} // Handle change
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={2}>
                        <Button variant="secondary" size="sm" onClick={() => updateSpeaker(item.id)}>
                            Update
                        </Button>
                    </Col>
                </Row >
                <p>{item.text}</p>
                <audio controls src={API_BASE_URL + '/file/' + item.filename}>
                    Your browser does not support the audio element.
                </audio>
            </div>

        </Col>
    )) : [];

    return (
        <>
        <Clientheader />
            <Row >
                <Col md={12} className='mb-3'>
                    This is not a mandatory step, but if at all possible identify the speakers below and then press the Continue button to create your transcription.
                </Col>
            </Row>
            <Row >
                {mappedClips}
            </Row>
            <Row >
                <Col md={12} className='mb-3'>
                    <div className="d-grid gap-2">
                        <Button variant="primary" onClick={() => doneAudio('donemappingspeakers')}>
                            Continue
                        </Button>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default Transcriptionresult;
