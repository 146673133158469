import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { get } from '../../services/apiServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './style.css';
import { CreateFileStatus } from '../../interfaces';
import { useAuth } from '../../context/AuthProvider';
import Clientheader from '../../components/clientheader';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'ocrapola';

interface FileUploadProps {
    doneAudio: any;
    processid: any;
}

const CreatingTranscript: React.FC<FileUploadProps> = ({ processid, doneAudio }) => {
    const [filename, setFilename] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const { user } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await get('/createtranscript/' + processid, user ? user?.token : null);
                const pollResponse = response.data as CreateFileStatus;
                setFilename(pollResponse.filename)
                setPage(1)
            } catch (err) {
                console.log('Error fetching data');
            }
        };
        fetchData()
    }, []);

    return (
        <>
            <Clientheader />
            <Container>
                <Row style={{marginTop:'100px', marginBottom: '100px'}}>
                    {page === 0 &&
                        <Col md={12}>
                            <FontAwesomeIcon icon={faSpinner} className="rotate" size='2x' style={{ marginRight: '12px' }} />
                            <div>
                                <p>Creating final transwcript</p>
                                <p>Depending on the size of the recording this process can take a few minutes</p>
                            </div>
                        </Col>
                    }
                    {page === 1 &&
                        <a href={`${API_BASE_URL}/file/${filename}`} download="document.docx">Download Document</a>
                    }
                </Row>
            </Container>
        </>
    );
};

export default CreatingTranscript;
