import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { post } from '../../services/apiServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import PlainHeader from '../../components/plainheader';
import PublicFooter from '../../components/footer';

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = React.useState('');
  const [error, setError] = useState('');
  const [processing, setProcessing] = useState(false);
  const [isreset, setIsreset] = useState(false);

  interface ResetResponse {
    data: {
      success: boolean;
      message: string;
    };
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setProcessing(true)
    try {
      const response: ResetResponse = await post('/resetpassword', { email }, null);
      console.log('....', response)
      if (response.data.success == true) {
        setIsreset(true)
      } else {
        setError(response.data.message)
        setProcessing(false)
      }

    } catch (error: any) {
      setError(error.response.data.message);
    }
  };

  return (
    <>
      <PlainHeader />
      <Container className='main-container mt-5'>
        <Row className="justify-content-md-center pt-5">
          <Col md={4}>
            <div className="container mt-5">
              <h2>Reset Password</h2>

              {!isreset &&
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="email">
                    <Form.Label>Enter the email address associated with your account</Form.Label>
                    <Form.Control
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter email"
                      required
                    />
                  </Form.Group>

                  <p className='mt-2'>
                    Back to <a href="/login" > Sign in</a>
                  </p>

                  {error && <Alert variant="danger">{error}</Alert>}
             
                  <Button variant="primary" type="submit" className="w-100" disabled={processing}>
                    {processing &&
                      <FontAwesomeIcon icon={faSpinner} className="rotate" size='2x' style={{ marginRight: '12px' }} />
                    }
                    {!processing &&
                      <>Send password reset link</>
                    }
                  </Button>
                </Form>
              }

              {isreset &&
                <Alert variant="success">
                  A password reset link has been sent to your email address {email} registered with Transcribo.
                </Alert>
              }
            </div>
          </Col>
        </Row>
      </Container>
      <PublicFooter />
    </>
  );
};

export default ForgotPassword;