import { Container, Row, Col } from 'react-bootstrap';
import PlainHeader from '../../components/plainheader';
import PublicFooter from '../../components/footer';

const PrivacyPolicy = () => {

    return (
        <>
            <PlainHeader />
            <Container className='main-container mt-5'>
                <Row className="justify-content-md-center pt-5">
                    <Col md={10}>
                        <h3>Privacy Policy</h3>
                        <h5>1. About this Notice</h5>
                        <p>
                            This Privacy Notice ("Notice") explains how we (as defined below) collect, share and use any information that, alone or in combination with other information, relates to you ("Personal Data") when you ("you" and "your") use our website [site] (the "Website"), or when you contract with us as a customer or a supplier.
                        </p>

                        <h5>1.2. Rights</h5>
                        <p>
                            This Notice also sets out the rights that you have in relation to the Personal Data that we process about you and how you can exercise them
                        </p>

                        <h5>1.3. Privacy Obligations</h5>
                        <p>
                            Codebin Consulting Services treats compliance with its privacy obligations seriously. This is why we have developed this Notice, which describes the standards that Codebin Consulting Services applies to protect Personal Data.
                        </p>

                        <h5>1.4. Data Controller</h5>
                        <p>
                            For the purposes of this Notice, Codebin Consulting Services ("us", "we", or "our") acts as the data controller for the Personal Data that is collected via the Website. As a data controller, Codebin Consulting Services is responsible for ensuring that the processing of Personal Data complies with applicable data protection law, and specifically with the General Data Protection Regulation.
                        </p>

                        <h5>1.5. Read Carefully</h5>
                        <p>
                            Please take the time to read this Notice carefully. If you have any questions or comments, please contact ralphklein72@gmail.com .
                        </p>

                        <h5>2. What Personal Data does SmartShort.co collect and why?</h5>
                        <p>
                            Email address, electronic identification data (incl. when you sign up through Google or Outlook):<br />
                        </p>
                        <p>
                            <b>Why we collect it:</b>
                            <ul>
                                <li>
                                    Providing our services to our clients (including free-trial users), including managing the access to our services and enabling the pooling featureLegal grounds for processing.
                                </li>
                                <li>
                                    Contractual necessity
                                </li>
                            </ul>
                        </p>
                        <p>
                            Identification data, electronic identification data and Internet connection utilization data:
                        </p>
                        <p>
                            <b>Why we collect it:</b>
                            <ul>
                                <li>
                                    To draw up commercial and frequentation statistics related to the use of our Services
                                </li>
                                <li>
                                    Our legitimate interest in knowing the popularity of our services in order to take action to optimize its visibility or its interface
                                </li>
                                <li>
                                    To provide tailored responses to your requests for information through the chatbot or when you contact us
                                </li>
                                <li>
                                    Our legitimate interest in responding to requests
                                </li>
                            </ul>
                        </p>

                        <h5>2.1 Automatic Information Collection</h5>
                        <p>
                            We also collect certain information automatically from your device. This information includes your IP address, device type, unique device identification numbers, browser type, broad geographic location (e.g., country or city-level location), and other technical information. We may also collect information about how your device has interacted with our Website, including the pages accessed and links clicked.
                        </p>
                        <p>
                            Collecting this information enables us to better understand our Website visitors, their origin, and their interests in the content on our Website. We use this information for internal analytics purposes to improve the quality and relevance of our Website for our visitors.Some of this information may be collected using cookies and similar tracking technology.
                        </p>

                        <h5>3. Who does SmartShort.co share your Personal Data with</h5>
                        <p>
                            We disclose your Personal Data to the following categories of recipients:
                        </p>
                        <p>
                            Our third-party vendors and service providers who process data on our behalf or for purposes described in this Notice or notified to you at the time of data collection. We share data with these vendors and service providers in areas such as invoice and payment services, customer and lead support (chat on the Website), website hosting, marketing and newsletters, and data analytics.
                            Any competent law enforcement body, regulatory, government agency, court, or other third party where we believe disclosure is necessary: (i) as required by applicable law or regulation, (ii) to exercise, establish, or defend our legal rights, or (iii) to protect your vital interests or those of any other person.Our auditors, advisors, legal representatives, and similar agents in connection with the advisory services they provide to us for legitimate business purposes, under contractual prohibition from using the Personal Data for any other purpose.
                            A potential buyer (and its agents and advisers) in connection with any proposed purchase, merger, or acquisition of any part of our business, provided that the buyer is informed to use your Personal Data only for the purposes disclosed in this Notice.Any other person if you have provided your prior consent to the disclosure.
                        </p>

                        <h5>4. How We Protect Your Privacy</h5>
                        <p>
                            We will process Personal Data in accordance with this Notice, as follows:
                        </p>
                        <p>
                            Fairness: We will process Personal Data fairly. This means that we are transparent about how we process Personal Data and that we will process it in accordance with applicable law.<br />
                            Lawfulness: We will process Personal Data only on lawful grounds.<br />
                            Purpose Limitation: We will process Personal Data for specified and legitimate purposes and will not process it in a manner that is incompatible with those purposes, unless permitted by applicable data protection laws.<br />
                            Data Minimization: We will process Personal Data that is adequate, relevant, and limited to what is necessary to achieve the purposes for which the data are processed.<br />
                            Data Accuracy: We take appropriate measures to ensure that the Personal Data we hold about you is accurate, complete, and, where necessary, kept up to date. However, it is also your responsibility to ensure that your Personal Data is kept as accurate, complete, and current as possible by promptly informing SmartShort.co of any changes or errors. You should notify us of any changes to the Personal Data that we hold about you (e.g., a change of email address).<br />
                            Data Security: We use appropriate technical and organizational measures to protect the Personal Data we collect and process about you. The measures we use are designed to provide a level of security appropriate to the risk of processing your Personal Data.<br />
                            Limited Retention: We keep your Personal Data in a form that allows us to identify you for as long as necessary to achieve the purposes for which we are processing your data and do not store your data for longer unless we must comply with applicable laws.<br />
                        </p>

                        <h5>5. Data Storage, Retention, and Deletion</h5>
                        <p>
                            We retain Personal Data we collect from you where we have an ongoing legitimate business need to do so (for example, to provide you with a service you have requested or to comply with applicable legal, tax, or accounting requirements). We retain customer data for no longer than 2 years following the end of our contractual relationship. However, we will retain the personal data related to accounting for no longer than 10 years.When we have no ongoing legitimate business need to process your Personal Data, we will either delete or anonymize it. If this is not possible (for example, because your Personal Data has been stored in backup archives), then we will securely store your Personal Data and isolate it from any further processing until deletion is possible.
                        </p>

                        <h5>6. International Transfers of Data</h5>
                        <p>
                            Your Personal Data may be transferred to, and processed in, countries other than the country in which you are resident. These countries may have data protection laws that are different from the laws of your country (and, in some cases, may not be as protective).Specifically, our servers are located in the United States, and our third-party service providers operate in the EU and the United States. This means that when we collect your Personal Data, we may process it in any of these countries.However, we have taken appropriate safeguards to require that your Personal Data will remain protected in accordance with this Notice. These include implementing the European Commission’s Standard Contractual Clauses for transfers of Personal Data with our third-party service providers and partners. Further details can be provided upon request.
                        </p>

                        <h5>7. Your Data Protection Rights</h5>
                        <p>
                            You have the following data protection rights:
                        </p>
                        <p>
                            If you wish to access, correct, update, or request deletion of your Personal Data, you can do so at any time by contacting us. In addition, in certain circumstances, as stipulated in the applicable data protection legislation, you can object to the processing of your Personal Data, ask us to restrict processing of your Personal Data, or request portability of your Personal Data. Again, you can exercise these rights by contacting us.
                            If we have collected and processed your Personal Data with your consent, then you can withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your Personal Data conducted in reliance on lawful processing grounds other than consent.
                            You have the right to opt-out of marketing communications we send you at any time. You can exercise this right by clicking on the "unsubscribe" or "opt-out" link in the marketing emails we send you.
                            If you have a complaint or concern about how we are processing your Personal Data, then we will endeavor to address such concern(s). If you feel we have not sufficiently addressed your complaint or concern, you have the right to complain to a data protection authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority.
                        </p>
                        <h5>8. Linking to Other Websites</h5>
                        <p>
                            The Website may contain hyperlinks to websites owned and operated by third parties. These websites have their own privacy policies, and we urge you to review them. They will govern the use of Personal Data you submit whilst visiting these websites.We do not accept any responsibility or liability for the privacy practices of such third-party websites, and your use of such websites is at your own risk.
                        </p>
                        <h5>9. Updates to this Notice</h5>
                        <p>
                            We may update this Notice from time to time in response to changing legal, technical, or business developments. When we update our Notice, we will take appropriate measures to inform you, consistent with the significance of the changes we make. We will obtain your consent to any material Notice changes if and where this is required by applicable data protection laws.You can see when this Notice was last updated by checking the "last updated" date displayed at the top of this Notice.
                        </p>
                    </Col>
                </Row>
            </Container>
            <PublicFooter />
        </>
    );
};

export default PrivacyPolicy;