import React, { useState } from 'react';
import { Form, Button, Row, Col, Alert, Container } from 'react-bootstrap';
import { post } from '../../services/apiServices';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../context/AuthProvider';
import PlainHeader from '../../components/plainheader';
import PublicFooter from '../../components/footer';

const Register: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();

  interface RegisterResponse {
    data: {
      success: boolean;
      message: string;
    };
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setProcessing(true)
    try {
      const response: RegisterResponse = await post('/register', { name, email, password }, user ? user?.token : null);

      if (response.data.success == true) {
        navigate('/verify')
      } else {
        setError(response.data.message)
        setProcessing(false)
      }

    } catch (error: any) {
      setError(error.response.data.message);
    }
  };

  return (
    <>
      <PlainHeader />
      <Container className='main-container mt-5'>
        <Row className="justify-content-md-center pt-5" >
          <Col md={4}>
            <h2 className="text-center">Register</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="name" className="mb-3">
                <Form.Label>Fulle name</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter your name"
                  required
                />
              </Form.Group>

              <Form.Group controlId="email" className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter email"
                  required
                />
              </Form.Group>

              <Form.Group controlId="password" className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter password"
                  required
                />
              </Form.Group>
              <p className='mt-2'>
                Already have an account? <a href="/login" > Sign in</a>
              </p>

              {error && <Alert variant="danger">{error}</Alert>}
              <Button variant="primary" type="submit" className="w-100" disabled={processing}>
                {processing &&
                  <FontAwesomeIcon icon={faSpinner} className="rotate" size='2x' style={{ marginRight: '12px' }} />
                }
                {!processing &&
                  <>Register</>
                }
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
      <PublicFooter />
    </>
  );
};

export default Register;