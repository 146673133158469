import React, { useState } from 'react';

import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { post } from '../../services/apiServices';
import { useNavigate } from 'react-router-dom';

interface LoginResponse {
    data: {
        success: boolean;
        message: string;
        name: string;
        id: number;
        token: string;
    };
}

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState<string | null>('');
    const [processing, setProcessing] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setProcessing(true)
        const queryParams = new URLSearchParams(window.location.search);
        const tokenFromUrl = queryParams.get('token');
        if (!tokenFromUrl) {
            setError('Invalid token provided');
            setProcessing(false)
            return;
        }

        if (password != confirmPassword) {
            setError('password and confirm password do not match');
            setProcessing(false)
        }
        else {
            try {
                const response: LoginResponse = await post('/setnewpassword', { password: password, resetToken: tokenFromUrl }, null);
                if (response.data.success != true) {
                    console.log(response)
                    setError(response.data.message)
                    setProcessing(false)
                } else {
                    await localStorage.setItem('user', JSON.stringify(response.data));                  
                    window.location.href = '/home' 
                }
            } catch (error: any) {
                setError(error.response.data.message);
            }
        }
    };

    return (
        <Container className='main-container'>
            <Row className="justify-content-md-center pt-5">
                <Col md={4}>
                    <h2 className="text-center">Reset Password</h2>

                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formUsername">
                            <Form.Control
                                type="password"
                                required
                                placeholder="New Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formUsername">
                            <Form.Control
                                type="password"
                                required
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </Form.Group>

                        {error && <Alert variant="danger">{error}</Alert>}

                        <Button variant="primary" type="submit" className="w-100" disabled={processing}>
                            {processing &&
                                <FontAwesomeIcon icon={faSpinner} className="rotate" size='2x' style={{ marginRight: '12px' }} />
                            }
                            {!processing &&
                                <>Reset Password</>
                            }
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default ResetPassword;