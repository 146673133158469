import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, ProgressBar } from 'react-bootstrap';
import { get } from '../../services/apiServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './style.css';
import { PollResponse } from '../../interfaces';
import { useAuth } from '../../context/AuthProvider';
import Clientheader from '../../components/clientheader';

interface FileUploadProps {
    doneAudio: any;
    processid: any
}

const ProcessingComponent: React.FC<FileUploadProps> = ({ processid, doneAudio }) => {
    const [progress, setProgress] = useState(0);
    const [statusMsg, setStatusMsg] = useState('');
    const { user } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await get('/pollaudiostatus/' + processid, user ? user?.token : null);
                const pollResponse = response.data as PollResponse;

                setStatusMsg(pollResponse.statusMessage)
                switch (pollResponse.status) {
                    case 'sampled':
                        doneAudio('success');
                        break;
                    case 'error':
                        doneAudio('error');
                        break;
                    case 'transcribed':
                        doneAudio('transcribed');
                        setProgress(2)
                        break;
                    case 'processing':
                        doneAudio('processing');
                        setProgress(0)
                        break;
                    case 'polling':
                        doneAudio('polling');
                        setProgress(1)
                        break;
                    case 'notokens':
                        doneAudio('notokens');
                        break;
                    case 'done':
                        doneAudio('done');
                        break;
                }
            } catch (err) {
                console.log('Error fetching data');
            }
        };
        const intervalId = setInterval(fetchData, 5000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            <Clientheader />
            <Container>
                <Row >
                    <Col md={12}>
                        {progress === 0 && (
                            <div>
                                <FontAwesomeIcon icon={faSpinner} className="rotate" size='2x' style={{ marginRight: '12px' }} />
                                <p>Converting audio</p>
                                <p>Depending on the size of the recording this process can take up to 30 min</p>
                                <p>You do not have to wait here for it to complete, you can check in on it under "Transactions" in the left menu panel</p>
                                {/* <ProgressBar animated now={progress} /> */}
                            </div>
                        )}
                        {progress === 1 && (
                            <div>
                                <FontAwesomeIcon icon={faSpinner} className="rotate" size='2x' style={{ marginRight: '12px' }} />
                                <p>Extracting and transcribing</p>
                                <p>Depending on the size of the recording this process can take up to 30 min</p>
                                <p>You do not have to wait here for it to complete, you can check in on it under "Transactions" in the left menu panel</p>
                                {/* <ProgressBar animated now={progress} /> */}
                            </div>
                        )}
                        {progress === 2 && (
                            <div>
                                <FontAwesomeIcon icon={faSpinner} className="rotate" size='2x' style={{ marginRight: '12px' }} />
                                <p>Formatting and building final output</p>
                                <p>We are now formatting the final document</p>
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ProcessingComponent;
