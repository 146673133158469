import React, { useState, useEffect, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';

const PlainHeader: React.FC = () => {
    const navigate = useNavigate();

    const handleNavigation = (q: string) => {
        switch (q) {
          case 'home':
            navigate('/')
            break;
          case 'logout':
            localStorage.removeItem('user');
            navigate('/login');
            break;
        };
      }
    return (
        <>
            <header id="header" className="header d-flex align-items-center fixed-top ">
                <div className="container-fluid position-relative d-flex align-items-center">

                    <a href="/" className="logo d-flex align-items-center me-auto">
                        <img src="assets/img/logo.png" alt="" />
                        <h1 className="sitename">Transcribo</h1>
                    </a>

                    <nav id="navmenu" className="navmenu">
                        <ul>
                            <li><a href="#" onClick={e => handleNavigation('home')}>Home</a></li>
                        </ul>
                        <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
                    </nav>


                </div>
            </header>
        </>
    );
};

export default PlainHeader;
