import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Landing from './pages/Landing';
import VerifyRegistration from './pages/VerifyRegistration';
import { PrivateRoute } from './components/privateroute';
import { AuthProvider } from './context/AuthProvider';
import ResetPassword from './pages/ResetPassword/resetpassword';
import TermsOfService from './pages/TermsofService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Chatbot from './components/chatbot';
import PaymentPostback from './pages/PaymentPostback';
import PaymentPage from './pages/PaymentPage';
import './App.css';

const App: React.FC = () => {

  return (
    <main className="main">
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/verify" element={<VerifyRegistration />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/paymentresponse" element={<PaymentPostback />} />            
            <Route path="/payments" element={<PaymentPage />} />
            <Route path="/" element={<Landing />} />
            {/* <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} /> */}

            <Route path="/home" element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            } />

            {/* Catch-all 404 Route */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </AuthProvider>     
      <Chatbot />
    </main>
  );
};

export default App;