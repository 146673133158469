import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { post } from '../../services/apiServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import GoogleLogin from '../../components/googlelogin';
import { useAuth } from '../../context/AuthProvider';
import PlainHeader from '../../components/plainheader';
import PublicFooter from '../../components/footer';

interface LoginResponse {
  data: {
    success: boolean;
    message: string;
    name: string;
    id: number;
    token: string;
  };
}

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setProcessing(true)
    if (!email || !password) {
      setError('Please fill in both fields.');
      setProcessing(false)
    } else {

      const response: LoginResponse = await post('/login', { email: email, password: password }, user ? user?.token : null);
      if (response.data.success == false) {
        setError(response.data.message)
        setProcessing(false)
      } else {
        console.log('.............',response.data)
        localStorage.setItem('user', JSON.stringify(response.data));
        window.location.href = '/home'
      }
    }
  };

  const handleLoginSuccess = (response: any) => {
    window.location.href = '/home'
  };

  const handleLoginFailure = (error: any) => {
    setError('Unable to log you in using this method.');
  };


  return (
    <>
      <PlainHeader />
      <Container className='main-container mt-5'>
        <Row className="justify-content-md-center pt-5" >
          <Col md={4} >
            <h3 className="text-center mb-4">Sign in to your account</h3>

            <GoogleLogin
            clientId="155552978395-3gb291o7dfaa0kb4hiaeo2ujlv9atsae.apps.googleusercontent.com"
            onSuccess={handleLoginSuccess}
            onFailure={handleLoginFailure}
          />
            <br />
            <Form onSubmit={handleLogin}>
              <Form.Group className="mb-3" controlId="formUsername">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>

              <p className='mt-2'>
                <a href="/forgot-password" >Forgot password</a>
              </p>

              <p className='mt-2'>
                Don't have an account? <a href="/register" > Sign up</a>
              </p>

              {error && <Alert variant="danger">{error}</Alert>}
              <Button variant="primary" type="submit" className="w-100" disabled={processing} >
                {processing &&
                  <FontAwesomeIcon icon={faSpinner} className="rotate" size='2x' style={{ marginRight: '12px' }} />
                }
                {!processing &&
                  <>Login</>
                }
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
      <PublicFooter />
    </>
  );
};

export default Login;