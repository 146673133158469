// apiService.ts
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'ocrap';  // Replace with your API base URL
const TOKEN ='sdfsdf'

export interface ApiResponse<T> {
  success: boolean;
  data: T;
  error?: string;
}

// Generic GET request
export const get = async <T>(endpoint: string, token: string | null): Promise<ApiResponse<T>> => {
  try {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Authorization': `${token}`,
      },
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Error fetching data');
    }

    return { success: true, data };
  } catch (error) {
    return { success: false, data: {} as T, error: (error as Error).message };
  }
};

// Generic POST request
export const upload = async <T>(endpoint: string, body: any, token: string | null): Promise<ApiResponse<T>> => {
  try {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      method: 'POST',
      headers: {
        'Authorization': `${token}`,
      },
      body: body,
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Error posting data');
    }

    return { success: true, data };
  } catch (error) {
    return { success: false, data: {} as T, error: (error as Error).message };
  }
};

// Generic POST request
export const post = async <T>(endpoint: string, body: any, token: string | null): Promise<ApiResponse<T>> => {
  
  try {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
      body: JSON.stringify(body),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Error updating data');
    }

    return { success: true, data };
  } catch (error) {
    return { success: false, data: {} as T, error: (error as Error).message };
  }
};


