import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import PlainHeader from '../../components/plainheader';
import { get } from '../../services/apiServices';
import { useNavigate } from 'react-router-dom';

const PaymentPostback = () => {
    const [param, setParam] = useState<string | null>('');
    const queryParams = new URLSearchParams(window.location.search);
    const navigate = useNavigate();

    useEffect(() => {
        const ref = queryParams.get('reference');
        setParam(ref);
        const fetchData = async () => {
            try {
                console.log('.......', ref)
                const response = await get('/updatePayment/' + ref, null);
            } catch (err) {
                console.log('Error fetching data');
            }
        };
        fetchData()
        // const intervalId = setInterval(fetchData, 5000);
        // return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            <PlainHeader />
            <Container className='main-container mt-5'>
                <Row className="justify-content-md-center pt-5">
                    <Col md={10}>
                        Thank you for your payment, click the button below to start transcribing.                    
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col md={10}>
                        <Button onClick={() => navigate('/home')} className="mt-3">
                            Members area
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default PaymentPostback;